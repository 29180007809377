const logger = {
    /**
     * 控制台输出信息
     * @param content : 要输出的字符串;
     * @param title :字符串的标题，默认为'调试输出';
     */
    info: (content: string | number | object | [], title: string = '调试输出'): void => {
        if (process.env.REACT_APP_DEBUG === "true") {
            let t = 'background: #1475B2; color: #fff;padding:5px;border-radius:2px;'
            console.log(`%c ${title} ⇘ `, t, content)
        }
    },
    /**
     * 控制台输出警告
     * @param content : 要输出的字符串;
     * @param title :符串的标题，默认为'警告信息';
     */
    warning: (content: string | object | [], title: string = '警告信息') => {
        let t = 'background: #fca104; color: #000;padding:5px;border-radius:2px;'
        let c = 'background: #606060; color: #fff;padding:5px;'
        console.log(`%c ${title} ⇘ %c ${JSON.stringify(content)} `, t, c)
    },
    /**
     * 控制台输出错误
     * @param content : 要输出的字符串;
     * @param title :符串的标题，默认为'系统错误';
     */
    errmsg: (content: string | object | [], title: string = '系统错误') => {
        let t = 'background: #de1c31; color: #fff;padding:5px;border-radius:2px;'
        let c = 'background: #606060; color: #fff;padding:5px;'
        console.log(`%c ${title} ⇘ %c ${JSON.stringify(content)} `, t, c)
    },
    initc: () => {
        let t = 'background: #22a2c3; color: #ffffff;'
        let c = 'background: #ffffff; color: #22202e;'
        let p = 'padding-left:60px;line-height:60px; font-size: 22px; background:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K") no-repeat;color: #61DAFB;font-weight:bold;'
        let l = 'border-radius:2px;color: #22a2c3;';
        console.log(`%c 轻互联科技有限公司 %c QingHuLian Technology CO.,Ltd.`, t, c);
        // console.log(`%c to ${'https://www.qhulian.com'}`, 'color: #22a2c3;font-size: 16px;font-weight: bold;text-decoration: underline;');
        console.log(`%c
...............................................
...QQQQQQQ........HH..........HH...LL..........
..QQ......QQ......HH..........HH...LL..........
.QQ........QQ.....HH..........HH...LL..........
QQ..........QQ....HHHHHHHHHHHHHH...LL..........
.QQ.....Q..QQ.....HH..........HH...LL..........
..QQ.....QQQ......HH..........HH...LL..........
...QQQQQQQ..QQQ...HH..........HH...LLLLLLLLLLLL
...............................................`, l);
        console.log('%c Made With React', p)
    }
}

export default logger