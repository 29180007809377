import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { MantineProvider, Loader } from '@mantine/core';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logger from "@utils/logger"
import MasterPage from "@components/masterpage"


/** 程序入口 */
const ProgramEntry = () => {
  useEffect(() => {
    logger.initc();
  }, []);

  return (<Suspense fallback={<div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}><Loader size="lg" /></div>}>
    <Router>
      <Routes>
        <Route path="/*" key="index" element={<MasterPage />} />
      </Routes>
    </Router>
  </Suspense>)
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MantineProvider>
    <div id='app'>
      <ProgramEntry />
    </div>
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
