import React, { lazy } from 'react';
import { createStyles } from '@mantine/core';
import { Routes, Route } from "react-router-dom";

import Navbar from "@components/navbar";
import Footer from "@components/footer";

import mockdata from "@/mock/navbardata.json";

// import HomePage from "@/pages/home";
// import AboutPage from "@/pages/about";
// import Recruitment from "@/pages/recruitment";
// import VisionMission from "@/pages/visionmission";
// import Certifications from "@/pages/certifications";
// import Products from "@/pages/products";
// import Solutions from "@/pages/solutions";
// import UserCase from "@/pages/usercase";
// import Service from "@/pages/service";
// import NotFound from "@/pages/notfound";
// import Details from "@/pages/details";
// import QuotedPrice from "@/pages/quotedprice";

const HomePage = lazy(() => import('@/pages/home'));
const AboutPage = lazy(() => import('@/pages/about'));
const Recruitment = lazy(() => import('@/pages/recruitment'));
const VisionMission = lazy(() => import('@/pages/visionmission'));
const Certifications = lazy(() => import('@/pages/certifications'));
const Products = lazy(() => import('@/pages/products'));
const Solutions = lazy(() => import('@/pages/solutions'));
const UserCase = lazy(() => import('@/pages/usercase'));
const Service = lazy(() => import('@/pages/service'));
const NotFound = lazy(() => import('@/pages/notfound'));
const Details = lazy(() => import('@/pages/details'));


/** 声明页面样式 */
const useStyles = createStyles(() => ({
    root: {
        width: "100vw"
    },
    containers: {
        width: "100%"
    },
    setFixed: {
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 999
    },
    setTexture: {
        background: "linear-gradient(rgba(248,249,250,0.7),rgba(248,249,250,0.7)), url(https://spu.qhulian.com/texture/goodcloud.png) repeat"
    }
}));




const MasterPage = () => {
    /** 引入样式和合并样式的函数 */
    const { classes, cx } = useStyles();
    return (<div className={classes.root}>
        <div id='site_header' className={cx(classes.containers, classes.setFixed)}>
            <Navbar links={mockdata.navbar} />
        </div>
        <div id='site_content' className={classes.containers}>
            <Routes>
                <Route path="/" key="home" element={<HomePage />} />
                <Route path="/about/:type" key="about" element={<AboutPage />} />
                <Route path="/recruitment/:type" key="recruitment" element={<Recruitment />} />
                <Route path="/visionmission" key="visionmission" element={<VisionMission />} />
                <Route path="/certifys" key="certifys" element={<Certifications />} />
                <Route path="/products" key="products" element={<Products />} />
                <Route path="/solutions" key="solutions" element={<Solutions />} />
                <Route path="/usercase" key="usercase" element={<UserCase />} />
                <Route path="/service/:type" key="service" element={<Service />} />
                <Route path="/details/:id" key="details" element={<Details />} />
                <Route path="/*" key="notfound" element={<NotFound />} />
            </Routes>
        </div>
        <div id='site_footer' className={cx(classes.containers, classes.setTexture)}>
            <Footer data={mockdata.footer} />
        </div>
    </div>);
}

export default MasterPage