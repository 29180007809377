import React, { useEffect, useState, CSSProperties, useRef } from 'react';
import { createStyles, Header, Container, Group, Burger, Paper, Transition, Image, HeaderProps, Navbar, Grid, SimpleGrid, ThemeIcon, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { motion, AnimatePresence, Variants } from "framer-motion";
import {
    IconArrowNarrowRight
} from '@tabler/icons';
import { useNavigate } from 'react-router-dom';

/** 导航栏状态参数 */
type THeaderContainerProps = {
    navstatus: "transparent" | "white";
}
/** Header组件自定义参数 */
type TUnionHeader = THeaderContainerProps & HeaderProps;

/** 导航栏组件参数 */
type THeaderResponsiveProps = {
    links: TLinkProps;
}

/**导航栏高度 */
const HEADER_HEIGHT = 60;

/** 导航栏样式 */
const useStyles = createStyles((theme, { navstatus }: THeaderContainerProps, getRef) => {
    const icon = getRef('icon');
    return ({
        root: {
            position: 'relative',
            zIndex: 999,
            backgroundColor: navstatus === 'transparent' ? 'rgba(88,88,88,0)' : 'rgba(255,255,255,1)',
            borderBottom: 'none',
            transition: 'background-color 0.3s',
            // backgroundColor: 'rgba(255,255,255,0.8)',
            // backdropFilter: 'blur(28px)'
        },

        dropdown: {
            position: 'absolute',
            top: HEADER_HEIGHT,
            left: 0,
            right: 0,
            zIndex: 0,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderTopWidth: 0,
            overflow: 'hidden',
            [theme.fn.largerThan('sm')]: {
                display: 'none',
            },
        },

        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            maxWidth: 1440
        },

        links: {
            [theme.fn.smallerThan('sm')]: {
                display: 'none',
            },
        },

        burger: {
            [theme.fn.largerThan('sm')]: {
                display: 'none',
            },
        },

        link: {
            display: 'block',
            lineHeight: 1,
            padding: '8px 12px',
            borderRadius: theme.radius.lg,
            textDecoration: 'none',
            transition: 'color,background-color 0.5s',
            color: navstatus === 'transparent' ? theme.white : theme.black,
            fontSize: theme.fontSizes.sm,
            fontWeight: 500,
            '&:hover': {
                backgroundColor: navstatus === 'transparent' ? theme.colors.dark[6] : theme.colors.gray[1]
            },
            [theme.fn.smallerThan('sm')]: {
                borderRadius: 0,
                padding: theme.spacing.md,
            },
        },
        menuroot: {
            overflow: 'hidden',
            width: "100%",
            backgroundColor: "rgba(255,255,255,1)",
            backdropFilter: "blur(28px)"
        },
        menucontainer: {
            maxWidth: 1440
        },
        menucontent: {
            maxWidth: 1440,
            width: "100%",
            boxSizing: "border-box",
            overflow: 'hidden'
        },
        gridcontainer: {
            maxWidth: 1440,
            width: "100%",
            boxSizing: "border-box",
            padding: "50px 0px 80px 0px"
        },
        navtitle: {
            ...theme.fn.focusStyles(),
            display: 'flex',
            transition: 'color 0.3s',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: theme.fontSizes.md,
            color: theme.black,
            padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
            borderRadius: theme.radius.sm,
            fontWeight: "bold"
        },
        navtitlelink: {
            ...theme.fn.focusStyles(),
            display: 'flex',
            transition: 'color 0.3s',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: theme.fontSizes.md,
            color: theme.black,
            padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
            borderRadius: theme.radius.sm,
            fontWeight: "bold",
            '&:hover': {
                color: theme.colors.blue[6],
                [`& .${icon}`]: {
                    color: theme.colors.blue[6]
                }
            }
        },
        navtitleicon: {
            ref: icon,
            transition: 'color 0.3s',
            color: theme.black,
            marginRight: theme.spacing.sm
        },
        navlink: {
            ...theme.fn.focusStyles(),
            display: 'flex',
            transition: 'color 0.3s',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: theme.fontSizes.sm,
            color: theme.colors.gray[6],
            padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
            borderRadius: theme.radius.sm,
            fontWeight: 500,
            '&:hover': {
                color: theme.black
            }
        },
        navlinkactive: {
            ...theme.fn.focusStyles(),
            display: 'flex',
            transition: 'color 0.3s',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: theme.fontSizes.sm,
            color: theme.colors.blue[6],
            padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
            borderRadius: theme.radius.sm,
            fontWeight: 500
        },
        contentitem: {
            display: 'flex',
            cursor: 'pointer'
        },
        itemIcon: {
            padding: theme.spacing.xs,
            marginRight: theme.spacing.md,
        },
        itemTitle: {
            marginBottom: theme.spacing.xs / 2,
        },
    });
});


/** 导航栏外层容器函数组件 */
const HeaderContainer = (props: TUnionHeader) => {
    const { navstatus } = props;
    const { classes } = useStyles({ navstatus });
    return (<Header className={classes.root} {...props}>
        {props.children}
    </Header>);
}

/** 导航栏动画参数 */
const variants: { [key: string]: Variants } = {
    grid: {
        hidden: {
            opacity: 0,
            height: 0,
            transition: {
                delay: 0.2,
                // staggerChildren: 0.01,
                staggerDirection: -1
            }
        },
        visible: {
            opacity: 1,
            height: "auto"
            // transition: {
            //     staggerChildren: 0.03,
            //     when: "beforeChildren",
            //     delayChildren: 0.2
            // }
        }
    },
    text: {
        hidden: (i) => ({
            opacity: 0,
            x: -50,
            transition: {
                delay: i * 0.01
            }
        }),
        visible: (i) => ({
            opacity: 1,
            x: 0,
            transition: {
                delay: i * 0.03
            }
        })
    },
    item: {
        hidden: (i) => ({
            opacity: 0,
            x: 50,
            transition: {
                delay: i * 0.01
            }
        }),
        visible: (i) => ({
            opacity: 1,
            x: 0,
            transition: {
                delay: i * 0.03
            }
        })
    }
}

/** 导航栏点击样式 */
const linkActive: { style: CSSProperties } = {
    style: {
        backgroundColor: "#E0E0E0"
    }
}

/** 导航栏组件 */
const HeaderResponsive = ({ links }: THeaderResponsiveProps) => {
    /** 控制手机网页下导航栏的显示和隐藏 */
    const [opened, { toggle, close }] = useDisclosure(false);
    /** 控制导航栏显示与隐藏 */
    const menuVisible = useRef<boolean>(false);
    /** 仅用于触发重新渲染页面 */
    const [, setUpdateView] = useState<number>(Date.now());
    /** 导航栏状态(透明或白色) */
    const navbarStatus = useRef<"transparent" | "white">("transparent");
    /** 选中的菜单下表[下标0代表一级菜单，下标1代表二级菜单] */
    const [menuIndex, setMenuIndex] = useState<Array<number>>([-1, 0]);
    /** 引入声明的导航栏样式 */
    const { classes } = useStyles({ navstatus: navbarStatus.current });
    const navigate = useNavigate();

    /** 组件加载时触发 */
    useEffect(() => {
        /** 添加绑定监听滚动事件 */
        window.addEventListener('scroll', scrollEvent);
        /** 组件卸载时触发 */
        return () => {
            /** 移除绑定监听滚动事件 */
            window.removeEventListener('scroll', scrollEvent);
        }
    }, []);//eslint-disable-line

    /** 监听滚动事件 */
    const scrollEvent = () => {
        if ((document.documentElement.scrollTop || document.body.scrollTop) > 0) {
            if (navbarStatus.current !== 'white') {
                navbarStatus.current = "white";
                setUpdateView(Date.now());
            }
        } else {
            if (navbarStatus.current !== 'transparent') {
                if (!menuVisible.current) {
                    navbarStatus.current = "transparent";
                    setUpdateView(Date.now());
                }
            }
            if (opened) {
                toggle();
            }
        }
    }

    /** 点击一级菜单事件 */
    const linkMouseTap = (index: number, mtype: boolean) => {
        if (mtype) {
            setMenuIndex([index, 0]);
            if (navbarStatus.current !== "white") {
                navbarStatus.current = "white";
                setUpdateView(Date.now());
            }
            if (!menuVisible.current) {
                menuVisible.current = true;
                setUpdateView(Date.now());
            }
        } else {
            linkMouseLeave();
            navigate(`/${links[index].link}`);
        }
    }

    /** 鼠标移开导航栏触发 */
    const linkMouseLeave = () => {
        if ((document.documentElement.scrollTop || document.body.scrollTop) <= 0 && navbarStatus.current !== 'transparent') {
            setTimeout(() => {
                navbarStatus.current = "transparent";
                setUpdateView(Date.now());
            }, 500);
        }
        setTimeout(() => {
            setMenuIndex([-1, 0]);
        }, 500);
        if (menuVisible.current) {
            menuVisible.current = false;
            setUpdateView(Date.now());
        }
        if (opened) {
            close();
        }
    }

    /** 点击不同二级菜单时触发 */
    const menuMouseTap = (index: number) => {
        const linkIndex = menuIndex[0];
        setMenuIndex([linkIndex, index]);
    }

    /** 点击三级菜单项时触发 */
    const navigateTo = (params: string | number) => {
        const level1 = links[menuIndex[0]]?.child?.menus[menuIndex[1]].link;
        if (!level1 || level1 === "#") return;
        if (!params) return;
        let navStr = "/" + level1;
        if (params !== "#") {
            navStr += "/" + params;
        }
        navigate(navStr);
        linkMouseLeave();
    }


    const mobileNavigateTo = (params: string) => {
        if (params === "#") return;
        if (!params) {
            navigate("/");
        } else {
            navigate(`/${params}`);
        }
    }

    /** 循环显示一级菜单项 */
    const items = links.map((link, index) => {
        const aprops = index === menuIndex[0] ? linkActive : {};
        return (<a
            key={link.label}
            href={link.link}
            {...aprops}
            className={classes.link}
            onClick={(event) => {
                event.preventDefault();
                close();
                linkMouseTap(index, link.mtype);
            }}> {link.label}</a >
        );
    });

    /** 移动端循环显示一级菜单*/
    const burgerItems = links.map((link, index) => {
        const aprops = index === menuIndex[0] ? linkActive : {};
        return (<a
            key={link.label}
            href="#"
            {...aprops}
            className={classes.link}
            onClick={(event) => {
                event.preventDefault();
                close();
                mobileNavigateTo(link.link);
            }}> {link.label}</a >
        );
    });

    /** 移动端切换菜单是否显示 */
    const burgerClick = () => {
        if (!opened) {
            if (navbarStatus.current !== "white") {
                navbarStatus.current = "white";
                setUpdateView(Date.now());
            }
        }
        if (opened) {
            if ((document.documentElement.scrollTop || document.body.scrollTop) <= 0 && navbarStatus.current !== 'transparent') {
                navbarStatus.current = "transparent";
                setUpdateView(Date.now());
            }
        }
        toggle();
    }

    return (
        <HeaderContainer navstatus={navbarStatus.current} height={HEADER_HEIGHT} onMouseLeave={linkMouseLeave}>
            <Container className={classes.header}>
                <Image
                    onClick={() => navigate("/")}
                    width={160}
                    height={28}
                    style={{ cursor: "pointer" }}
                    radius='md'
                    src={`https://spu.qhulian.com/material/${navbarStatus.current === 'transparent' ? 'logo_dark' : 'logo_light'}.png`}
                    alt="qhulian logo" />
                <Group spacing={28} className={classes.links}>
                    {items}
                </Group>

                <Burger opened={opened} onClick={burgerClick} className={classes.burger} color={navbarStatus.current === 'transparent' ? "white" : "black"} size="sm" />
                <Transition transition="pop-top-right" duration={200} mounted={opened}>
                    {(styles) => (
                        <Paper className={classes.dropdown} withBorder style={styles}>
                            {burgerItems}
                        </Paper>
                    )}
                </Transition>
            </Container>

            <div className={classes.menuroot}>
                <Container className={classes.menucontainer}>
                    <AnimatePresence mode='wait'>
                        <motion.div className={classes.menucontent} variants={variants.grid} initial="hidden" animate={menuVisible.current ? "visible" : "hidden"}>
                            <div className={classes.gridcontainer}>
                                <Grid columns={24}>
                                    <Grid.Col span={4}>
                                        <Navbar height="auto" withBorder={false}>
                                            <motion.a
                                                variants={variants.text}
                                                href="#"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    linkMouseLeave();
                                                    mobileNavigateTo(links[menuIndex[0]]?.child?.link || "#");
                                                }}
                                                className={links[menuIndex[0]]?.child?.link !== "#" ? classes.navtitlelink : classes.navtitle}>
                                                <span>{links[menuIndex[0]]?.child?.title}</span>
                                                {links[menuIndex[0]]?.child?.link !== "#" && <IconArrowNarrowRight className={classes.navtitleicon} stroke={1.5} />}
                                            </motion.a>
                                            <Navbar.Section>
                                                {links[menuIndex[0]]?.child?.menus.map((item, index) => {
                                                    return <motion.a key={item.key}
                                                        variants={variants.text}
                                                        initial="hidden"
                                                        animate={menuVisible.current ? "visible" : "hidden"}
                                                        custom={index}
                                                        href="#"
                                                        className={menuIndex[1] === index ? classes.navlinkactive : classes.navlink}
                                                        onClick={(event) => { event.preventDefault(); menuMouseTap(index); }}>
                                                        <span>{item.title}</span>
                                                    </motion.a>
                                                })}
                                            </Navbar.Section>
                                        </Navbar>
                                    </Grid.Col>
                                    <Grid.Col span={20}>
                                        <SimpleGrid
                                            sx={{ marginTop: 15 }}
                                            cols={3}
                                            spacing={50}>
                                            {links[menuIndex[0]]?.child?.menus[menuIndex[1]].contents.map((item, index) => {
                                                return <motion.div
                                                    onClick={() => navigateTo(item.link)}
                                                    initial="hidden"
                                                    animate={menuVisible.current ? "visible" : "hidden"}
                                                    custom={index}
                                                    variants={variants.item}
                                                    className={classes.contentitem}
                                                    key={item.key}>
                                                    <ThemeIcon variant="light" color="gray" className={classes.itemIcon} size={60} radius="md">
                                                        <Image src={item.image} />
                                                    </ThemeIcon>
                                                    <div>
                                                        <Text weight={700} size="lg" className={classes.itemTitle}>
                                                            {item.title}
                                                        </Text>
                                                        <Text color="dimmed">{item.description}</Text>
                                                    </div>
                                                </motion.div>
                                            })}
                                        </SimpleGrid>
                                    </Grid.Col>
                                </Grid>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                </Container>
            </div>

        </HeaderContainer>);
}

export default HeaderResponsive;